import {EnvName} from '../app/models/enums/env-name';
import {EnvConfig} from '../app/models/env-config';

import {BUILD_TIMESTAMP, COMMIT_HASH, VERSION} from './build';

export const env: EnvConfig = {
  name: 'SIGEFI Portal',
  environment: EnvName.PRODUCTION,
  version: VERSION,
  build: BUILD_TIMESTAMP,
  vapidPublicKey: 'BEkCDKRKMkg-is5NBGC0n6myOyYOTh_5VzpGf8lLH-vEvck1djDYewmaYaD-AX6iGf_Xuy3uq2JCDeU_6ZWFdTc',
  statusPageUrl: 'https://status.sigefi.app',
  ably: {
    key: 'GzPnqQ.EabazA:0e3lQEenZPNvHIt5XLJ_VtHJ7-DxwO_m9iFksxqVIpM',
    channel: 'notifications',
    disconnectedTimeout: 5000,
    suspendedTimeout: 10000,
    heartbeatInterval: 10000
  },
  api: {
    url: 'https://api.sigefi.app',
    port: 443,
    version: 'v1',
    timeout: 15000
  },
  app: {
    activeEventBusLogger: false,
    activeProdMode: true,
    activeRollbar: true,
    activeSW: true
  },
  rollbar: {
    accessToken: '97443b3975c4479a8f99f30570ef9e68',
    codeVersion: COMMIT_HASH
  }
};
